import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1125 1125" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >
<path fill="#00ADEF" opacity="1.000000" stroke="none" 
	d="
M658.000000,1126.000000 
	C438.690918,1126.000000 219.881836,1126.000000 1.036372,1126.000000 
	C1.036372,751.065796 1.036372,376.131592 1.036372,1.098700 
	C375.892639,1.098700 750.785278,1.098700 1125.838989,1.098700 
	C1125.838989,375.999939 1125.838989,750.999939 1125.838989,1126.000000 
	C970.131165,1126.000000 814.315613,1126.000000 658.000000,1126.000000 
M687.781494,538.264038 
	C689.517029,528.997192 692.278503,519.788452 692.807495,510.453217 
	C694.906433,473.413910 684.343506,440.420807 658.707275,413.096069 
	C625.999023,378.233490 585.476318,365.253693 538.745300,373.390076 
	C508.226532,378.703674 482.939178,394.206024 463.125732,418.248749 
	C439.556061,446.849487 430.231598,479.869690 434.281525,516.366028 
	C437.168518,542.382690 447.276672,565.688843 464.877106,585.465515 
	C497.768982,622.424377 539.067017,637.410461 587.294250,628.283447 
	C637.744446,618.735718 670.871887,587.613708 687.781494,538.264038 
M334.995361,707.500000 
	C334.993317,714.327026 335.136749,721.158203 334.938141,727.979553 
	C334.805695,732.528076 336.673187,734.319824 341.181549,734.046204 
	C348.633789,733.593750 356.105865,733.477905 363.561615,733.072937 
	C384.597626,731.930359 398.583221,716.882812 397.278015,695.070862 
	C396.531128,682.588745 387.840393,669.554932 375.738312,665.587402 
	C364.184723,661.799744 352.389038,663.051880 340.645813,662.587769 
	C336.160095,662.410461 334.871826,664.354004 334.925568,668.536011 
	C335.088074,681.188904 334.991180,693.845093 334.995361,707.500000 
M779.940735,687.536743 
	C773.373108,683.574951 766.561401,683.372681 759.371338,685.759827 
	C757.491089,686.384033 755.006165,686.275024 753.121765,685.600647 
	C746.727661,683.312256 742.992004,685.543274 742.988708,692.083618 
	C742.979004,711.379028 743.101990,730.675659 742.901733,749.968994 
	C742.852905,754.669617 745.010803,755.518433 748.990784,755.499268 
	C752.869080,755.480530 755.406616,754.942444 755.087219,750.071899 
	C754.725403,744.555054 755.003784,738.996216 755.003784,733.314331 
	C772.410522,736.723267 784.019775,732.300232 788.903320,720.875610 
	C794.164673,708.567078 790.748352,695.055298 779.940735,687.536743 
M651.097412,734.961121 
	C663.919434,733.467041 673.852844,722.771240 673.178162,707.729980 
	C672.422913,690.890198 657.370605,680.028687 641.552734,685.693481 
	C639.694702,686.358948 637.200134,686.262512 635.303589,685.622192 
	C628.560486,683.345581 624.991150,685.571716 624.988647,692.489014 
	C624.981689,711.640015 625.141907,730.792969 624.889160,749.940613 
	C624.819824,755.195862 627.550415,755.431580 631.466980,755.473999 
	C635.286987,755.515381 637.377930,754.663757 637.089050,750.187561 
	C636.733154,744.671814 637.004028,739.115662 637.004028,733.716431 
	C642.017822,734.204102 646.110901,734.602234 651.097412,734.961121 
M441.557220,689.996338 
	C434.563263,682.137329 418.074951,683.269043 410.619507,687.166443 
	C407.223297,688.941895 407.518921,692.398438 408.825836,695.003723 
	C409.378387,696.105225 412.558899,696.270569 414.520721,696.198730 
	C417.954865,696.072876 421.371918,695.074280 424.793121,695.090271 
	C431.267426,695.120544 434.092896,699.210999 432.373016,706.017090 
	C427.546875,706.017090 422.578217,705.673706 417.675781,706.096985 
	C410.170288,706.745056 404.777374,712.562317 404.258057,719.875793 
	C403.764679,726.823608 407.931549,731.950073 415.539856,734.247253 
	C421.328400,735.994995 426.820404,734.975220 431.996979,732.544739 
	C434.545258,731.348267 435.994934,731.611206 438.589386,732.710754 
	C441.936218,734.129272 446.255432,734.317322 449.831451,733.541016 
	C451.045471,733.277405 451.274994,728.479187 452.137390,725.018860 
	C445.750397,725.399109 445.431732,721.695740 445.415497,717.776306 
	C445.396118,713.111633 445.797974,708.396240 445.241669,703.796631 
	C444.698517,699.305542 443.177948,694.932678 441.557220,689.996338 
M607.573975,727.077881 
	C614.940735,719.390259 615.892700,710.138672 612.817566,700.659912 
	C609.576538,690.669800 601.641235,685.597961 591.479187,684.380859 
	C574.337769,682.327759 558.018982,695.729919 562.578735,715.547119 
	C567.116333,735.268188 591.617737,741.673584 607.573975,727.077881 
M529.500732,662.585510 
	C523.508667,662.577332 517.515747,662.627869 511.524811,662.539734 
	C507.680450,662.483215 506.842499,664.540283 506.908081,667.947632 
	C506.971008,671.218201 507.007751,674.060547 511.368988,674.027283 
	C515.696350,673.994263 520.051270,674.331665 524.344666,673.950134 
	C529.150391,673.523132 530.321472,675.586182 530.270569,679.998352 
	C530.088013,695.808899 530.176636,711.622864 530.207275,727.435425 
	C530.218933,733.461670 530.833923,734.024719 536.743652,734.018921 
	C542.762695,734.013000 543.000732,733.760620 543.002625,727.362976 
	C543.007263,711.383911 543.104187,695.403870 542.950867,679.426331 
	C542.911926,675.365417 544.201721,673.670410 548.402527,673.967957 
	C552.543274,674.261230 556.735962,673.817627 560.877625,674.104614 
	C565.553955,674.428711 566.407227,671.968994 566.496826,668.027283 
	C566.589722,663.940857 565.078796,662.422424 560.959351,662.504883 
	C550.809937,662.708008 540.653931,662.580444 529.500732,662.585510 
M498.600098,733.997681 
	C501.368011,733.750366 505.035431,735.093262 505.011322,730.114868 
	C504.962189,719.952209 505.342010,709.773499 504.891510,699.630493 
	C504.413635,688.872253 495.355530,682.228821 484.786957,684.610474 
	C480.656067,685.541382 476.739258,687.422241 472.548187,688.934204 
	C471.100311,684.018127 466.932983,684.888184 463.265106,685.412109 
	C459.305389,685.977722 459.994141,689.370178 459.992706,692.153076 
	C459.986481,704.148743 460.155975,716.147766 459.913483,728.138489 
	C459.817902,732.863892 461.716949,734.094666 466.136841,734.159058 
	C470.784088,734.226746 472.288391,732.600891 472.092957,728.033264 
	C471.779846,720.716003 471.997711,713.375427 472.014893,706.044739 
	C472.030334,699.459900 477.142822,694.945923 484.459503,695.023315 
	C490.606415,695.088318 492.970245,698.057739 492.987549,705.797729 
	C493.004669,713.461670 493.098694,721.127686 492.946228,728.788574 
	C492.876953,732.268799 493.878479,734.305664 498.600098,733.997681 
M716.987488,706.467651 
	C716.682678,710.719727 717.243591,715.290283 715.829407,719.134216 
	C714.418823,722.968140 705.630859,725.208679 701.497986,723.661682 
	C696.546936,721.808411 696.047424,717.447998 696.030212,712.969849 
	C696.000854,705.314758 695.838318,697.654663 696.062195,690.006348 
	C696.190918,685.608459 693.657349,685.147644 690.342712,685.172119 
	C686.975769,685.196960 683.911377,685.349854 683.964050,690.035828 
	C684.066895,699.187439 683.775879,708.347412 684.039917,717.492554 
	C684.418396,730.603394 694.093262,737.649841 706.803772,734.142456 
	C710.102600,733.232178 713.174988,731.501465 716.503967,730.082581 
	C718.144470,735.558533 722.685059,734.278442 726.377747,733.783569 
	C730.671326,733.208130 728.899170,729.315735 728.933350,726.841492 
	C729.103333,714.529053 728.871521,702.210632 729.083923,689.899475 
	C729.161194,685.418091 726.611877,685.173279 723.350525,685.152832 
	C719.924194,685.131348 716.892822,685.366089 716.973145,690.002441 
	C717.062439,695.159729 716.990112,700.319885 716.987488,706.467651 
z"/>
<path fill="#FCFFFF" opacity="1.000000" stroke="none" 
	d="
M687.632812,538.638672 
	C670.871887,587.613708 637.744446,618.735718 587.294250,628.283447 
	C539.067017,637.410461 497.768982,622.424377 464.877106,585.465515 
	C447.276672,565.688843 437.168518,542.382690 434.281525,516.366028 
	C430.231598,479.869690 439.556061,446.849487 463.125732,418.248749 
	C482.939178,394.206024 508.226532,378.703674 538.745300,373.390076 
	C585.476318,365.253693 625.999023,378.233490 658.707275,413.096069 
	C684.343506,440.420807 694.906433,473.413910 692.807495,510.453217 
	C692.278503,519.788452 689.517029,528.997192 687.632812,538.638672 
M506.410339,480.499817 
	C506.408936,504.481995 506.319977,528.464600 506.445007,552.446167 
	C506.503082,563.587402 511.298157,568.030457 522.485046,568.020935 
	C539.305908,568.006653 556.128723,568.179382 572.946960,567.970398 
	C587.145508,567.794006 600.118225,563.406372 611.349976,554.711060 
	C613.883972,552.749329 615.959595,549.364441 616.804932,546.244141 
	C618.124023,541.375183 615.235596,537.694275 611.233032,534.971863 
	C607.409790,532.371460 603.572327,532.383118 599.485107,534.485718 
	C593.750732,537.435608 588.105896,541.949951 582.065735,542.631653 
	C568.594788,544.152100 554.898132,544.081238 541.302246,543.920288 
	C534.185913,543.836060 530.060303,538.518005 530.044800,531.267212 
	C530.003479,511.948303 530.000488,492.629211 530.046570,473.310333 
	C530.065247,465.486847 535.306030,460.207764 543.165344,460.046448 
	C548.159424,459.943970 553.166443,460.227142 558.152039,459.998016 
	C573.014648,459.314972 587.254456,460.463470 599.895935,469.834076 
	C604.831055,473.492279 611.575317,471.270081 615.279419,466.192017 
	C618.602051,461.636932 617.987366,454.919128 613.180664,450.920074 
	C603.769043,443.089844 592.847717,437.800903 580.633667,437.181488 
	C561.196533,436.195801 541.703491,436.224335 522.231995,436.039703 
	C511.367279,435.936646 506.514587,440.998077 506.427460,452.020325 
	C506.355042,461.179596 506.411255,470.339935 506.410339,480.499817 
M563.971619,502.316986 
	C563.562622,508.767426 571.135925,514.478027 577.020996,513.912048 
	C583.842041,513.256104 588.438843,507.560455 588.136902,500.418030 
	C587.865173,493.989746 582.519226,488.921875 576.060181,488.969574 
	C569.442261,489.018402 564.177002,494.463776 563.971619,502.316986 
M618.021973,474.246765 
	C618.611450,475.418793 619.114807,476.644287 619.804199,477.754242 
	C624.562683,485.414825 627.129578,493.646759 626.994873,502.757263 
	C626.860107,511.872467 623.873779,519.935913 619.224426,527.648926 
	C618.368164,529.069458 619.407898,531.632812 619.572510,533.668701 
	C621.307434,532.847290 623.721375,532.499084 624.673889,531.132324 
	C636.119690,514.709595 635.994385,489.286285 624.501770,472.790375 
	C623.665955,471.590759 622.046936,470.270782 620.781128,470.259705 
	C619.863159,470.251617 618.927063,472.315979 618.021973,474.246765 
M615.544861,490.146240 
	C613.657410,486.852448 612.058777,483.333008 609.745178,480.372192 
	C608.773254,479.128326 606.288940,479.066284 604.492065,478.467010 
	C604.748230,480.230255 604.407471,482.451965 605.354248,483.684814 
	C613.567017,494.378632 614.591553,508.552338 605.367004,520.446289 
	C604.406372,521.684937 605.045166,524.164124 604.941895,526.067688 
	C606.681580,525.283936 609.033691,524.973389 610.063232,523.640930 
	C617.671509,513.794373 619.710938,502.822418 615.544861,490.146240 
M592.975708,520.938965 
	C594.519653,519.902649 596.480835,519.181335 597.539856,517.778809 
	C604.402527,508.689911 604.181335,494.565948 597.040771,485.730988 
	C596.072571,484.533142 594.151978,484.105072 592.672302,483.320557 
	C592.875793,485.045502 592.507935,487.158752 593.374084,488.433411 
	C599.774841,497.852661 599.380920,507.013672 593.135071,516.300293 
	C592.374023,517.431885 592.570435,519.207275 592.975708,520.938965 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M334.995361,707.000000 
	C334.991180,693.845093 335.088074,681.188904 334.925568,668.536011 
	C334.871826,664.354004 336.160095,662.410461 340.645813,662.587769 
	C352.389038,663.051880 364.184723,661.799744 375.738312,665.587402 
	C387.840393,669.554932 396.531128,682.588745 397.278015,695.070862 
	C398.583221,716.882812 384.597626,731.930359 363.561615,733.072937 
	C356.105865,733.477905 348.633789,733.593750 341.181549,734.046204 
	C336.673187,734.319824 334.805695,732.528076 334.938141,727.979553 
	C335.136749,721.158203 334.993317,714.327026 334.995361,707.000000 
M382.359253,709.617920 
	C388.522461,694.703613 383.173309,680.017761 369.289856,675.850342 
	C363.576385,674.135315 357.218658,674.412781 351.140198,674.220215 
	C350.139038,674.188477 348.181793,676.399170 348.162109,677.598022 
	C347.938507,691.216309 347.911102,704.840820 348.131134,718.458862 
	C348.155151,719.944153 349.775818,722.307678 351.128357,722.722229 
	C360.573669,725.616882 376.637207,718.982239 382.359253,709.617920 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M780.255615,687.727783 
	C790.748352,695.055298 794.164673,708.567078 788.903320,720.875610 
	C784.019775,732.300232 772.410522,736.723267 755.003784,733.314331 
	C755.003784,738.996216 754.725403,744.555054 755.087219,750.071899 
	C755.406616,754.942444 752.869080,755.480530 748.990784,755.499268 
	C745.010803,755.518433 742.852905,754.669617 742.901733,749.968994 
	C743.101990,730.675659 742.979004,711.379028 742.988708,692.083618 
	C742.992004,685.543274 746.727661,683.312256 753.121765,685.600647 
	C755.006165,686.275024 757.491089,686.384033 759.371338,685.759827 
	C766.561401,683.372681 773.373108,683.574951 780.255615,687.727783 
M773.523804,722.198059 
	C774.435181,721.236633 775.510925,720.383911 776.230652,719.295654 
	C780.188782,713.310486 780.101501,707.113403 776.702759,700.970886 
	C773.458496,695.107666 767.953979,694.368591 762.015625,695.040649 
	C756.779846,695.633179 755.089050,697.538818 755.037048,702.700134 
	C754.995178,706.859253 754.988770,711.018799 754.985962,715.178162 
	C754.980652,722.996582 756.975952,725.150269 764.947205,724.898560 
	C767.623718,724.813965 770.259644,723.442566 773.523804,722.198059 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M650.650696,734.980713 
	C646.110901,734.602234 642.017822,734.204102 637.004028,733.716431 
	C637.004028,739.115662 636.733154,744.671814 637.089050,750.187561 
	C637.377930,754.663757 635.286987,755.515381 631.466980,755.473999 
	C627.550415,755.431580 624.819824,755.195862 624.889160,749.940613 
	C625.141907,730.792969 624.981689,711.640015 624.988647,692.489014 
	C624.991150,685.571716 628.560486,683.345581 635.303589,685.622192 
	C637.200134,686.262512 639.694702,686.358948 641.552734,685.693481 
	C657.370605,680.028687 672.422913,690.890198 673.178162,707.729980 
	C673.852844,722.771240 663.919434,733.467041 650.650696,734.980713 
M660.610596,714.625366 
	C660.742432,712.978577 660.982788,711.332153 660.988464,709.684937 
	C661.026062,698.807129 652.849243,692.455444 642.360657,695.326660 
	C639.194031,696.193481 636.652649,697.716858 636.954163,701.883545 
	C637.265137,706.181213 636.989014,710.520752 636.985840,714.841980 
	C636.979980,722.872864 639.500488,725.371948 647.670593,724.970703 
	C653.960510,724.661804 658.145813,721.289856 660.610596,714.625366 
z"/>
<path fill="#F1FDFE" opacity="1.000000" stroke="none" 
	d="
M441.821838,690.252075 
	C443.177948,694.932678 444.698517,699.305542 445.241669,703.796631 
	C445.797974,708.396240 445.396118,713.111633 445.415497,717.776306 
	C445.431732,721.695740 445.750397,725.399109 452.137390,725.018860 
	C451.274994,728.479187 451.045471,733.277405 449.831451,733.541016 
	C446.255432,734.317322 441.936218,734.129272 438.589386,732.710754 
	C435.994934,731.611206 434.545258,731.348267 431.996979,732.544739 
	C426.820404,734.975220 421.328400,735.994995 415.539856,734.247253 
	C407.931549,731.950073 403.764679,726.823608 404.258057,719.875793 
	C404.777374,712.562317 410.170288,706.745056 417.675781,706.096985 
	C422.578217,705.673706 427.546875,706.017090 432.373016,706.017090 
	C434.092896,699.210999 431.267426,695.120544 424.793121,695.090271 
	C421.371918,695.074280 417.954865,696.072876 414.520721,696.198730 
	C412.558899,696.270569 409.378387,696.105225 408.825836,695.003723 
	C407.518921,692.398438 407.223297,688.941895 410.619507,687.166443 
	C418.074951,683.269043 434.563263,682.137329 441.821838,690.252075 
M416.689423,718.106323 
	C416.625580,719.048462 416.142731,720.484985 416.570221,720.847351 
	C418.441498,722.432922 420.562744,724.818054 422.618988,724.843567 
	C426.012909,724.885681 429.836395,723.951294 432.613068,722.117554 
	C433.604797,721.462524 433.070801,715.852051 431.509033,714.510254 
	C427.929230,711.434631 420.195557,713.465881 416.689423,718.106323 
z"/>
<path fill="#F1FDFE" opacity="1.000000" stroke="none" 
	d="
M607.316895,727.318604 
	C591.617737,741.673584 567.116333,735.268188 562.578735,715.547119 
	C558.018982,695.729919 574.337769,682.327759 591.479187,684.380859 
	C601.641235,685.597961 609.576538,690.669800 612.817566,700.659912 
	C615.892700,710.138672 614.940735,719.390259 607.316895,727.318604 
M575.586060,702.441956 
	C572.086182,708.990173 574.372314,716.700500 578.017700,720.478760 
	C581.631348,724.224121 589.452209,725.347717 594.290161,722.988770 
	C600.172607,720.120605 602.680176,715.036560 602.062500,707.230957 
	C601.632263,701.793884 597.520386,696.700684 592.354126,695.205688 
	C586.176819,693.418152 580.616089,695.649536 575.586060,702.441956 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M530.000610,662.585693 
	C540.653931,662.580444 550.809937,662.708008 560.959351,662.504883 
	C565.078796,662.422424 566.589722,663.940857 566.496826,668.027283 
	C566.407227,671.968994 565.553955,674.428711 560.877625,674.104614 
	C556.735962,673.817627 552.543274,674.261230 548.402527,673.967957 
	C544.201721,673.670410 542.911926,675.365417 542.950867,679.426331 
	C543.104187,695.403870 543.007263,711.383911 543.002625,727.362976 
	C543.000732,733.760620 542.762695,734.013000 536.743652,734.018921 
	C530.833923,734.024719 530.218933,733.461670 530.207275,727.435425 
	C530.176636,711.622864 530.088013,695.808899 530.270569,679.998352 
	C530.321472,675.586182 529.150391,673.523132 524.344666,673.950134 
	C520.051270,674.331665 515.696350,673.994263 511.368988,674.027283 
	C507.007751,674.060547 506.971008,671.218201 506.908081,667.947632 
	C506.842499,664.540283 507.680450,662.483215 511.524811,662.539734 
	C517.515747,662.627869 523.508667,662.577332 530.000610,662.585693 
z"/>
<path fill="#F2FEFE" opacity="1.000000" stroke="none" 
	d="
M498.153870,733.998535 
	C493.878479,734.305664 492.876953,732.268799 492.946228,728.788574 
	C493.098694,721.127686 493.004669,713.461670 492.987549,705.797729 
	C492.970245,698.057739 490.606415,695.088318 484.459503,695.023315 
	C477.142822,694.945923 472.030334,699.459900 472.014893,706.044739 
	C471.997711,713.375427 471.779846,720.716003 472.092957,728.033264 
	C472.288391,732.600891 470.784088,734.226746 466.136841,734.159058 
	C461.716949,734.094666 459.817902,732.863892 459.913483,728.138489 
	C460.155975,716.147766 459.986481,704.148743 459.992706,692.153076 
	C459.994141,689.370178 459.305389,685.977722 463.265106,685.412109 
	C466.932983,684.888184 471.100311,684.018127 472.548187,688.934204 
	C476.739258,687.422241 480.656067,685.541382 484.786957,684.610474 
	C495.355530,682.228821 504.413635,688.872253 504.891510,699.630493 
	C505.342010,709.773499 504.962189,719.952209 505.011322,730.114868 
	C505.035431,735.093262 501.368011,733.750366 498.153870,733.998535 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M716.987793,705.973206 
	C716.990112,700.319885 717.062439,695.159729 716.973145,690.002441 
	C716.892822,685.366089 719.924194,685.131348 723.350525,685.152832 
	C726.611877,685.173279 729.161194,685.418091 729.083923,689.899475 
	C728.871521,702.210632 729.103333,714.529053 728.933350,726.841492 
	C728.899170,729.315735 730.671326,733.208130 726.377747,733.783569 
	C722.685059,734.278442 718.144470,735.558533 716.503967,730.082581 
	C713.174988,731.501465 710.102600,733.232178 706.803772,734.142456 
	C694.093262,737.649841 684.418396,730.603394 684.039917,717.492554 
	C683.775879,708.347412 684.066895,699.187439 683.964050,690.035828 
	C683.911377,685.349854 686.975769,685.196960 690.342712,685.172119 
	C693.657349,685.147644 696.190918,685.608459 696.062195,690.006348 
	C695.838318,697.654663 696.000854,705.314758 696.030212,712.969849 
	C696.047424,717.447998 696.546936,721.808411 701.497986,723.661682 
	C705.630859,725.208679 714.418823,722.968140 715.829407,719.134216 
	C717.243591,715.290283 716.682678,710.719727 716.987793,705.973206 
z"/>
<path fill="#08ADEC" opacity="1.000000" stroke="none" 
	d="
M506.410431,479.999817 
	C506.411255,470.339935 506.355042,461.179596 506.427460,452.020325 
	C506.514587,440.998077 511.367279,435.936646 522.231995,436.039703 
	C541.703491,436.224335 561.196533,436.195801 580.633667,437.181488 
	C592.847717,437.800903 603.769043,443.089844 613.180664,450.920074 
	C617.987366,454.919128 618.602051,461.636932 615.279419,466.192017 
	C611.575317,471.270081 604.831055,473.492279 599.895935,469.834076 
	C587.254456,460.463470 573.014648,459.314972 558.152039,459.998016 
	C553.166443,460.227142 548.159424,459.943970 543.165344,460.046448 
	C535.306030,460.207764 530.065247,465.486847 530.046570,473.310333 
	C530.000488,492.629211 530.003479,511.948303 530.044800,531.267212 
	C530.060303,538.518005 534.185913,543.836060 541.302246,543.920288 
	C554.898132,544.081238 568.594788,544.152100 582.065735,542.631653 
	C588.105896,541.949951 593.750732,537.435608 599.485107,534.485718 
	C603.572327,532.383118 607.409790,532.371460 611.233032,534.971863 
	C615.235596,537.694275 618.124023,541.375183 616.804932,546.244141 
	C615.959595,549.364441 613.883972,552.749329 611.349976,554.711060 
	C600.118225,563.406372 587.145508,567.794006 572.946960,567.970398 
	C556.128723,568.179382 539.305908,568.006653 522.485046,568.020935 
	C511.298157,568.030457 506.503082,563.587402 506.445007,552.446167 
	C506.319977,528.464600 506.408936,504.481995 506.410431,479.999817 
z"/>
<path fill="#0AACEA" opacity="1.000000" stroke="none" 
	d="
M563.970276,501.895691 
	C564.177002,494.463776 569.442261,489.018402 576.060181,488.969574 
	C582.519226,488.921875 587.865173,493.989746 588.136902,500.418030 
	C588.438843,507.560455 583.842041,513.256104 577.020996,513.912048 
	C571.135925,514.478027 563.562622,508.767426 563.970276,501.895691 
z"/>
<path fill="#17AAE3" opacity="1.000000" stroke="none" 
	d="
M618.010498,473.851501 
	C618.927063,472.315979 619.863159,470.251617 620.781128,470.259705 
	C622.046936,470.270782 623.665955,471.590759 624.501770,472.790375 
	C635.994385,489.286285 636.119690,514.709595 624.673889,531.132324 
	C623.721375,532.499084 621.307434,532.847290 619.572510,533.668701 
	C619.407898,531.632812 618.368164,529.069458 619.224426,527.648926 
	C623.873779,519.935913 626.860107,511.872467 626.994873,502.757263 
	C627.129578,493.646759 624.562683,485.414825 619.804199,477.754242 
	C619.114807,476.644287 618.611450,475.418793 618.010498,473.851501 
z"/>
<path fill="#17AAE3" opacity="1.000000" stroke="none" 
	d="
M615.705444,490.512024 
	C619.710938,502.822418 617.671509,513.794373 610.063232,523.640930 
	C609.033691,524.973389 606.681580,525.283936 604.941895,526.067688 
	C605.045166,524.164124 604.406372,521.684937 605.367004,520.446289 
	C614.591553,508.552338 613.567017,494.378632 605.354248,483.684814 
	C604.407471,482.451965 604.748230,480.230255 604.492065,478.467010 
	C606.288940,479.066284 608.773254,479.128326 609.745178,480.372192 
	C612.058777,483.333008 613.657410,486.852448 615.705444,490.512024 
z"/>
<path fill="#22AADD" opacity="1.000000" stroke="none" 
	d="
M592.649414,520.811646 
	C592.570435,519.207275 592.374023,517.431885 593.135071,516.300293 
	C599.380920,507.013672 599.774841,497.852661 593.374084,488.433411 
	C592.507935,487.158752 592.875793,485.045502 592.672363,483.320557 
	C594.151978,484.105072 596.072571,484.533142 597.040771,485.730988 
	C604.181335,494.565948 604.402527,508.689911 597.539856,517.778809 
	C596.480835,519.181335 594.519653,519.902649 592.649414,520.811646 
z"/>
<path fill="#07ACEB" opacity="1.000000" stroke="none" 
	d="
M382.198547,709.960327 
	C376.637207,718.982239 360.573669,725.616882 351.128357,722.722229 
	C349.775818,722.307678 348.155151,719.944153 348.131134,718.458862 
	C347.911102,704.840820 347.938507,691.216309 348.162109,677.598022 
	C348.181793,676.399170 350.139038,674.188477 351.140198,674.220215 
	C357.218658,674.412781 363.576385,674.135315 369.289856,675.850342 
	C383.173309,680.017761 388.522461,694.703613 382.198547,709.960327 
z"/>
<path fill="#0AACEA" opacity="1.000000" stroke="none" 
	d="
M773.218994,722.428711 
	C770.259644,723.442566 767.623718,724.813965 764.947205,724.898560 
	C756.975952,725.150269 754.980652,722.996582 754.985962,715.178162 
	C754.988770,711.018799 754.995178,706.859253 755.037048,702.700134 
	C755.089050,697.538818 756.779846,695.633179 762.015625,695.040649 
	C767.953979,694.368591 773.458496,695.107666 776.702759,700.970886 
	C780.101501,707.113403 780.188782,713.310486 776.230652,719.295654 
	C775.510925,720.383911 774.435181,721.236633 773.218994,722.428711 
z"/>
<path fill="#0AACEA" opacity="1.000000" stroke="none" 
	d="
M660.449158,715.001221 
	C658.145813,721.289856 653.960510,724.661804 647.670593,724.970703 
	C639.500488,725.371948 636.979980,722.872864 636.985840,714.841980 
	C636.989014,710.520752 637.265137,706.181213 636.954163,701.883545 
	C636.652649,697.716858 639.194031,696.193481 642.360657,695.326660 
	C652.849243,692.455444 661.026062,698.807129 660.988464,709.684937 
	C660.982788,711.332153 660.742432,712.978577 660.449158,715.001221 
z"/>
<path fill="#17AAE3" opacity="1.000000" stroke="none" 
	d="
M416.803558,717.741272 
	C420.195557,713.465881 427.929230,711.434631 431.509033,714.510254 
	C433.070801,715.852051 433.604797,721.462524 432.613068,722.117554 
	C429.836395,723.951294 426.012909,724.885681 422.618988,724.843567 
	C420.562744,724.818054 418.441498,722.432922 416.570221,720.847351 
	C416.142731,720.484985 416.625580,719.048462 416.803558,717.741272 
z"/>
<path fill="#0AACEA" opacity="1.000000" stroke="none" 
	d="
M575.782715,702.109253 
	C580.616089,695.649536 586.176819,693.418152 592.354126,695.205688 
	C597.520386,696.700684 601.632263,701.793884 602.062500,707.230957 
	C602.680176,715.036560 600.172607,720.120605 594.290161,722.988770 
	C589.452209,725.347717 581.631348,724.224121 578.017700,720.478760 
	C574.372314,716.700500 572.086182,708.990173 575.782715,702.109253 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
